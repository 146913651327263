import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const Sectigo = lazy(() => import('sections/hero/Sectigo.js'));
const PreciosSectigo = lazy(() => import('sections/services/PreciosSectigo.js'));
const Certimail = lazy(() => import('sections/about/Certimail.js'));
const BotonSanGeo = lazy(() => import('sections/services/BotonSanGeo.js'));
const SelloSitelock = lazy(() => import('sections/about/SelloSitelockSectigo.js'));
const SeguridadInformatica = lazy(() => import('sections/about/Ciberseguridad.js')); // He ajustado el nombre del archivo según lo que has mencionado
const EspecSSLSect = lazy(() => import('sections/services/EspecSSLSect.js'));
const SslGratis = lazy(() => import('sections/services/SslGratis.js'));
const FaqGeoTrust = lazy(() => import('../../sections/services/FaqGeotrust')); // Asegúrate de que la ruta sea correcta
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {
    
    
    return (
      <div>
        <Helmet>
          <title>Certificados Sectigo: SSL confiable y seguro a bajo precio</title>
          <meta name="description" content="Protege tu sitio web con SSL Sectigo. Garantizamos seguridad y confianza para tus usuarios con un cifrado sólido y certificados reconocidos a nivel mundial." />
        </Helmet>
        <Layout>
          <Sectigo />           
          <PreciosSectigo />          
          <EspecSSLSect />
          <SslGratis />
          <Certimail />          
          <SelloSitelock />
          <SeguridadInformatica />          
          <FaqGeoTrust />
          <BotonSanGeo />          
          <Llamenos />
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`